<template>
  <h5 v-bind:class="['mb-0', getClass]">{{ price | moneyFormat(currency) }}</h5>
</template>
<script>
export default {
  name: "ProductPrice",
  props: {
    price: {
      default: 0,
    },
    order: {
      default: 0,
    },
    competitorExists: {
      default: false,
    },
    currency: {
      default: "TRY",
    },
  },
  computed: {
    getClass() {
      let result = "text-dark";

      if (this.competitorExists && !isNaN(this.order)) {
        result = this.order > 2 ? "text-danger" : "text-success";
      }

      return result;
    },
  },
};
</script>